import React from "react";

const SearchBar = ({ input: keyword, onChange: setKeyword, disabled }) => {
  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control"
        key="random1"
        value={keyword}
        placeholder={"Buscar por producto, compañía o certificación"}
        onChange={(e) => setKeyword(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchBar;
