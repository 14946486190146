import React from "react";
import "../App.css";
import ScoreGauge from "./ScoreGauge";

export default class ScoreGauges extends React.Component {
  render() {
    return (
      <nav className="navbar fixed-bottom navbar-light bg-light footer py-0">
        <div className="footer-score-gauges">
          <ScoreGauge
            score={this.props.scoreEcodiseno}
            description={"Ecodiseño"}
          ></ScoreGauge>
        </div>
        <div className="footer-score-gauges">
          <ScoreGauge
            score={this.props.score13430}
            description={"EN 13430"}
          ></ScoreGauge>
        </div>
        <div className="footer-score-gauges">
          <ScoreGauge
            score={this.props.score}
            description={"Sello"}
          ></ScoreGauge>
        </div>
      </nav>
    );
  }
}
