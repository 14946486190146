import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import LogoEcoAAA from "../images/loop-eco-aaa.svg";
import LogoEcoAA from "../images/loop-eco-aa.svg";
import LogoEcoA from "../images/loop-eco-a.svg";
import LogoICIPCLoop from "../images/loop-certificate-icipc.svg";
import CertificateItem from "./CertificateItem";
import QRCode from "react-qr-code";
import { GoogleSpreadsheet } from "google-spreadsheet";

var certificationType;
var colorCertificate1 = "row certificateSeal certificateBackground";
var colorCertificate2 = "card-body row-eq-height certificateBackgroundWhite certificateSeal";
var colorCertificate3 = "certificateSeal";
var colorCertificate4 = "card-body row-eq-height certificateBackgroundWhite certificateSeal"

// Config variables
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const SHEET_ID = process.env.REACT_APP_SHEET_ID_CERTIFICATES;
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

const Product = (props) => {
  //const [certificateList, setCertificateList] = useState();
  const [certificateFound, setCertificateFound] = useState();
  const [certificateReady, setCertificateReady] = useState(false);

  const fetchData = async (loopCode) => {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const data = await sheet.getRows();
      const result = data.find((certificate) => {
        return (
          certificate.loopCode.toLowerCase() === loopCode.toLowerCase() ||
          certificate.barCode.toLowerCase() === loopCode.toLowerCase()
        );
      });
      setCertificateFound(result);
      setCertificateReady(true);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const getCertificationLogo = (certification) => {
    certificationType = certification
    if (certification === "A") {
      return (
        <img
          src={LogoEcoA}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      );
    } else if (certification === "AA") {
      return (
        <img
          src={LogoEcoAA}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      );
    } else if (certification === "AAA") {
      return (
        <img          
          src={LogoEcoAAA}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      );
    } else if (certification.toLowerCase().includes("mayor")) {
      var number;
      if(certification.substr(10, 1) === "%"){
        number = certification.substr(9, 1);
      }
      else{
        number = certification.substr(9, 2);
      }
      return (
        <img
          src={"https://loop.icipc.org/static/media/"+number+".svg"}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      );
    } else if(certification.substr(0, 3) === "100"){      
      number = "100";
      return (
        <img
          src={"https://loop.icipc.org/static/media/"+number+".svg"}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      );
    }else {
      return;
    }
  };

  useEffect(() => {
    fetchData(props.match.params.loopCode);
  }, [props.match.params.loopCode]);

  const certificateImages = (certificateFound) => {
    if (certificateFound.imageURL1 && certificateFound.imageURL2) {
      return (
        <div className="row text-center">
          <div className="col-md-6">
            <img
              src={certificateFound.imageURL1}
              alt="Company Logo 1"
              className="img-fluid certificateProductImage"
              style={{ maxHeight: "150px" }}
            />
          </div>
          <div className="col-md-6">
            <img
              src={certificateFound.imageURL2}
              alt="Company Logo 2"
              className="img-fluid certificateProductImage"
              style={{ maxHeight: "150px" }}
            />
          </div>
        </div>
      );
    } else if (certificateFound.imageURL1 && !certificateFound.imageURL2) {
      return (
        <div className="text-center">
          <img
            src={certificateFound.imageURL1}
            alt="Company Logo 1"
            className="img-fluid certificateProductImage"
            style={{ maxHeight: "150px", maxWidth: "50%" }}
          />
        </div>
      );
    } else if (!certificateFound.imageURL1 && certificateFound.imageURL2) {
      return (
        <div className="text-center">
          <img
            src={certificateFound.imageURL2}
            alt="Company Logo 1"
            className="img-fluid certificateProductImage"
            style={{ maxHeight: "150px", maxWidth: "50%" }}
          />
        </div>
      );
    } else {
      return;
    }
  };

  const seal = () => {
    if (certificateFound && certificateReady) {
      getCertificationLogo(certificateFound.certification)
      if(certificationType !== "A" && certificationType !== "AA" && certificationType !== "AAA"){
        colorCertificate1 = "row certificateSealCPR certificateBackgroundCPR";
        colorCertificate2 = "card-body row-eq-height certificateBackgroundWhite certificateSealCPR";
        colorCertificate3 = "certificateSealCPR";
        colorCertificate4 = "card-body row-eq-height certificateBackgroundWhite certificateSealCPR";
      }
      else{
        colorCertificate1 = "row certificateSeal certificateBackground";
        colorCertificate2 = "card-body row-eq-height certificateBackgroundWhite certificateSeal";
        colorCertificate3 = "certificateSeal";
        colorCertificate4 = "card-body row-eq-height certificateBackgroundWhite certificateSeal";
      }
      return (
        <div className="container">
          <div className="alert alert-success text-center" role="alert">
            El producto cuenta con certificación LOOP del ICIPC
          </div>
          <div className="container">
            <div id="containerCertificate" className={colorCertificate1}>
              <div className="col-md-4">
                <div className="h-100 text-center">
                  <div className="certificateTitle text-white">
                    Calificación LOOP
                  </div>
                  <div className={colorCertificate2}>
                    <div className={colorCertificate3}>
                      <h2>{certificateFound.certification}</h2>
                    </div>
                    {getCertificationLogo(certificateFound.certification)}
                    <p className="card-text">{certificateFound.loopCode}</p>
                    <QRCode
                      value={
                        "https://loop.icipc.org/#/verify/" +
                        certificateFound.loopCode
                      }
                      size={100}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="h-100">
                  <div className="certificateTitle text-center text-white">
                    Detalles de la certificación
                  </div>
                  <div className={colorCertificate4}>
                    <div className="text-center">
                      <img
                        src={LogoICIPCLoop}
                        alt="ICIPC Sello Loop"
                        className="certificateICIPCLogo rounded mx-auto d-block"
                      />
                    </div>
                    <div className="container">
                      <CertificateItem
                        property="Código LOOP"
                        label={certificateFound.loopCode}
                      ></CertificateItem>
                      <CertificateItem
                        property="Código de barras"
                        label={certificateFound.barCode}
                      ></CertificateItem>
                      <CertificateItem
                        property="Certificación"
                        label={
                          certificateFound.certification +
                          " " +
                          certificateFound.type
                        }
                      ></CertificateItem>
                      <CertificateItem
                        property="Producto"
                        label={certificateFound.product}
                      ></CertificateItem>
                      <CertificateItem
                        property="NIT de la Empresa"
                        label={certificateFound.nit}
                      ></CertificateItem>
                      <CertificateItem
                        property="Razón social"
                        label={certificateFound.company}
                      ></CertificateItem>
                      <CertificateItem
                        property="Dirección"
                        label={certificateFound.address}
                      ></CertificateItem>
                      <CertificateItem
                        property="Fecha otorgamiento"
                        label={certificateFound.date}
                      ></CertificateItem>
                      <CertificateItem
                        property="Fecha vencimiento"
                        label={certificateFound.dateExpiry}
                      ></CertificateItem>
                    </div>
                    <div className="container certificateProductImage">
                      {certificateImages(certificateFound)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (certificateReady) {
        return (
          <div className="alert alert-danger text-center" role="alert">
            El producto no cuenta con certificación LOOP del ICIPC
          </div>
        );
      } else {
        return (
          <div className="alert alert-info text-center" role="alert">
            Consultando...
          </div>
        );
      }
    }
  };

  return <div>{seal()}</div>;
};

export default withRouter(Product);
