import React from "react";

export default class ScoreGauge extends React.Component {
  render() {
    var color = "card text-white bg-success mb-3";
    if (this.props.score < 90 && this.props.score > 70) {
      color = "card text-black bg-warning mb-3";
    } else if (this.props.score <= 70) {
      color = "card text-white bg-danger mb-3";
    }
    return (
      <div className={color}>
        <div className="card-body">
          <h5 className="card-title">{this.props.score.toFixed(2)}%</h5>
          <p className="card-text">{this.props.description}</p>
        </div>
      </div>
    );
  }
}
