import React from "react";
import SearchItem from "./SearchItem";

const SearchCertificateList = ({ certificateList = [] }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <p>Producto</p>
        </div>
        <div className="col">Compañía</div>
        <div className="col">Certificado</div>
      </div>
      {certificateList.map((data, index) => {
        if (data) {
          return (
            <SearchItem key={index} data={data}></SearchItem>
          );
        }
        return null;
      })}
    </div>
  );
};

export default SearchCertificateList;
