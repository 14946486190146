import LogoEcoAAA from "../images/loop-eco-aaa.svg";
import LogoEcoAA from "../images/loop-eco-aa.svg";
import LogoEcoA from "../images/loop-eco-a.svg";

export function getSeal(score) {
  if (score > 95) {
    return {
      certification: "AAA",
      image: (
        <img
          src={LogoEcoAAA}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      ),
      description: "Alta capacidad de aprovechamiento",
      descriptionCertification: "Candidato a certificación ICIPC:",
    };
  }
  if (score <= 95 && score > 90) {
    return {
      certification: "AA",
      image: (
        <img
          src={LogoEcoAA}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      ),
      description: "Muy buen aprovechamiento con algunas restricciones",
      descriptionCertification: "Candidato a certificación ICIPC:",
    };
  }
  if (score <= 90 && score > 70) {
    return {
      certification: "A",
      image: (
        <img
          src={LogoEcoA}
          alt="ICIPC Sello Loop"
          className="img-fluid"
          style={{ height: "200px" }}
        />
      ),
      description: "Aprovechable con restricciones",
      descriptionCertification: "Candidato a certificación ICIPC:",
    };
  }
  if (score <= 70 && score > 50) {
    return {
      certification: "B",
      image: null,
      description: "Moderadamente aprovechable",
      descriptionCertification: "No es candidato a certificación ICIPC",
    };
  }
  if (score <= 50) {
    return {
      certification: "C",
      image: null,
      description: "Mínimamente aprovechable",
      descriptionCertification: "No es candidato a certificación ICIPC",
    };
  }
}

function calculateProductContainer(weightProductContainer) {
  if (weightProductContainer > 0.8) return 1;
  if (weightProductContainer <= 0.8 && weightProductContainer >= 0.4)
    return 0.9;
  if (weightProductContainer < 0.4 && weightProductContainer >= 0.2) return 0.7;
  if (weightProductContainer < 0.2 && weightProductContainer >= 0.1) return 0.5;
  if (weightProductContainer < 0.1) return 0;
}

export function getScore(surveydata) {
  // Ecodiseno
  var weightProductContainer =
    surveydata.weightProductContainer != null
      ? surveydata.weightProductContainer / 100
      : 1;
  weightProductContainer = calculateProductContainer(weightProductContainer);
  var packageType = surveydata.packageType != null ? surveydata.packageType / 100 : 1;
  var adhesive = surveydata.adhesive != null ? surveydata.adhesive / 100 : 1;
  var coating = surveydata.coating != null ? surveydata.coating / 100 : 1;
  var recycleCompatibility =
    surveydata.recycleCompatibility != null
      ? surveydata.recycleCompatibility / 100
      : 1;
  var materialBarrier =
    surveydata.materialBarrier != null
      ? surveydata.materialBarrier !== "null"
        ? surveydata.materialBarrier / 100
        : 1
      : 1;
  var materialBarrierWeight =
    surveydata.materialBarrierWeight != null &&
    surveydata.materialBarrier === "null"
      ? surveydata.materialBarrierWeight / 100
      : 1;

  // EN 13430
  var route = surveydata.route != null ? surveydata.route / 100 : 1;
  var color = surveydata.color != null ? surveydata.color / 100 : 1;
  var noPlasticPercentage =
    surveydata.noPlasticPercentage != null
      ? (100 - surveydata.noPlasticPercentage) / 100
      : 1;
  var materialIdentification =
    surveydata.materialIdentification != null
      ? surveydata.materialIdentification / 100
      : 1;
  var materialPPPE =
    surveydata.materialPPPE != null
      ? surveydata.materialPPPE !== "null"
        ? surveydata.materialPPPE / 100
        : 1
      : 1;
  var materialFloat =
    surveydata.materialFloat != null && surveydata.materialPPPE === "null"
      ? surveydata.materialFloat / 100
      : 1;
  var materialUniquePlastic =
    surveydata.materialUniquePlastic != null
      ? surveydata.materialUniquePlastic / 100
      : 1;

  var materialContamination =
    surveydata.materialContamination != null
      ? surveydata.materialContamination / 100
      : 1;

  var scoreEcodiseno =
    weightProductContainer *
    packageType *
    adhesive *
    coating *
    recycleCompatibility *
    materialBarrier *
    materialBarrierWeight *
    100;

  var score13430 =
    route *
    color *
    noPlasticPercentage *
    materialIdentification *
    materialPPPE *
    materialFloat *
    materialUniquePlastic *
    materialContamination *
    100;
  var score = scoreEcodiseno * 0.4 + score13430 * 0.6;

  return {
    scoreEcodiseno: scoreEcodiseno,
    score13430: score13430,
    score: score,
  };
}
