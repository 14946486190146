import React from "react";
import { getSeal } from "./Score.js";

export default class ScoreFinal extends React.Component {
  seal(score) {
    let currentScore = getSeal(score);

    return (
      
      <div className="card text-center">
        <div className="card-header">
          Calificación final de auto-evaluación de ecodiseño
        </div>
        <div className="card-body back">
          <h2>
            {currentScore.certification} ({score.toFixed(2)}%)
          </h2>
          <p className="card-text">{currentScore.description}</p>
          <h3>{currentScore.descriptionCertification}</h3>
          {currentScore.image}
          <div className="alert alert-warning text-center" role="alert">
            El presente resultado correponde a una auto-evaluación con criterios
            simplificados cuyo valor definitivo tendrá que ser determinado por
            el Instituto
          </div>
          <h4>¿Quieres cotizar este servicio o asesoría adicional?</h4>
          <a
            href="https://icipc.org/contactanos"
            target="_blank"
            className="btn btn-primary"
            type="button"
            rel="noreferrer"
          >
            Contáctanos
          </a>

        </div>
      </div>
    );
  }

  render() {
    return <div>{this.seal(this.props.score)}</div>;
  }
}
