import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import LogoLOOP from "./images/logo-loop.svg";
import Evaluation from "./components/Evaluation";
import Search from "./components/Search";
import Verify from "./components/Verify";
import Product from "./components/Product";
import {
  HashRouter,
  Route,
  Switch,
  Link,
  useRouteMatch,
} from "react-router-dom";
import "survey-react/survey.css";

const pageTitle = "ICIPC LOOP";

function MenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Link className={match ? "nav-link active" : "nav-link"} to={to}>
      {label}
    </Link>
  );
}

function App(props) {
  return (
    <main>
      <div className="container">
        <HashRouter>
          <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
            <Link
              className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
              to="/evaluation"
            >
              <img
                className="bi me-2"
                height="50"
                src={LogoLOOP}
                alt="Logo LOOP"
              />
            </Link>

            <ul className="nav nav-pills">
              <li className="nav-item">
                <MenuLink to="/evaluation" label="Auto-evaluación"></MenuLink>
              </li>
              <li className="nav-item">
                <MenuLink
                  to="/search"
                  label="Productos certificados"
                ></MenuLink>
              </li>
              <li className="nav-item">
                <MenuLink
                  to="/verify"
                  label="Verificar producto certificado"
                ></MenuLink>
              </li>
            </ul>
          </header>

          <Switch>
            <Route path="/evaluation">
              <Evaluation title={pageTitle + " | Auto-evaluación"} />
            </Route>
            <Route path="/search">
              <Search title={pageTitle + " | Productos certificados"} />
            </Route>
            <Route path="/verify/:loopCode">
              <Product
                title={pageTitle + " | Verificar producto certificado"}
              />
            </Route>
            <Route path="/verify">
              <Verify title={pageTitle + " | Verificar producto certificado"} />
            </Route>
            <Route path="/">
              <Evaluation title={pageTitle + " | Auto-evaluación"} />
            </Route>
          </Switch>
        </HashRouter>
        <footer className="pt-3 mt-4 text-muted border-top">
          &copy; 2021 ICIPC
        </footer>
      </div>
    </main>
  );
}

export default App;
