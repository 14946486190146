import React from "react";
import * as Survey from "survey-react";
import SurveyEco from "../surveyEco";
import ScoreFinal from "./ScoreFinal";
import ScoreGauges from "./ScoreGauges";
import { getScore, getSeal } from "./Score.js";
import { GoogleSpreadsheet } from "google-spreadsheet";

// Config variables
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
//const SHEET_ID = process.env.REACT_APP_SHEET_ID_ASSESSMENTS;
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

export default class SurveyComponent extends React.Component {
  constructor(props) {
    Survey.StylesManager.applyTheme("bootstrap");
    //Survey.defaultBootstrapCss.navigationButton = "btn btn-primary !important";
    Survey.defaultBootstrapCss.progressButtonsContainer = "btn btn-primary";
    Survey.defaultStandardCss.navigationButton = "btn btn-primary";
    super(props);
    this.state = {
      isCompleted: false,
      score: 100,
      scoreEcodiseno: 100,
      score13430: 100,
    };
    this.onCompleteComponent = this.onCompleteComponent.bind(this);
    this.onValueChangedComponent = this.onValueChangedComponent.bind(this);
    this.backSurvey = this.backSurvey.bind(this);
  }

  onCompleteComponent(survey) {
    this.setState({ isCompleted: true });
    this.addAnswerGoogleSheet(survey.data);
  }

  backSurvey() {
    this.setState({ isCompleted: false });
  }

  onValueChangedComponent(survey, options) {
    //console.log(survey.data);
    let score = getScore(survey.data);

    this.setState({
      scoreEcodiseno: score.scoreEcodiseno,
      score13430: score.score13430,
      score: score.score,
    });
  }

  addAnswerGoogleSheet(surveydata) {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
      try {
        await doc.useServiceAccountAuth({
          client_email: CLIENT_EMAIL,
          private_key: PRIVATE_KEY,
        });
        // loads document properties and worksheets
        await doc.loadInfo();

        //const sheet = doc.sheetsById[SHEET_ID];
        //const result = await sheet.addRow(row);
      } catch (e) {
        console.error("Error: ", e);
      }
    };

    let today = new Date();

    const newRow = {
      date: today.toISOString(),
      name: this.props.name,
      company: this.props.company,
      phone: this.props.phone,
      email: this.props.email,
      scoreEcodiseno: this.state.scoreEcodiseno,
      score13430: this.state.score13430,
      score: this.state.score,
      description: surveydata.description,
      certification: getSeal(this.state.score).certification,
      jsonData: JSON.stringify(surveydata),
    };

    appendSpreadsheet(newRow);
  }

  render() {
    var surveyRender = !this.state.isCompleted ? (
      <div className="survey">
        <Survey.Survey
          json={SurveyEco}
          showCompletedPage={false}
          onComplete={this.onCompleteComponent}
          onValueChanged={this.onValueChangedComponent}
        />
      </div>
    ) : null;

    var onCompleteComponent = this.state.isCompleted ? (
      <ScoreFinal score={this.state.score}></ScoreFinal>
    ) : null;

    var scoreGauges = this.state.isCompleted ? null : (
      <ScoreGauges
        scoreEcodiseno={this.state.scoreEcodiseno}
        score13430={this.state.score13430}
        score={this.state.score}
      ></ScoreGauges>
    );

    return (
      <div>
        {surveyRender}
        {onCompleteComponent}
        {scoreGauges}
      </div>
    );
  }
}
