import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Survey from "./Survey";

const Evaluation = (props) => {
  const [disclaimerAccept, setDisclaimerAccept] = useState(false);
  const [personalDataAccept, setPersonalDataAccept] = useState(false);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const afterSubmission = (event) => {
    event.preventDefault();
    setPersonalDataAccept(!personalDataAccept);
  };

  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  var disclaimer = (
    <div className="container">
      <div className="row">
        <div className="col-md">
          <div className="card text-center">
            <div className="card-header">Auto-evaluación</div>
            <div className="card-body">
              <h2>Aviso</h2>
              <p>
                La autoevaluación de LOOP ICIPC solo es adecuada para productos:
              </p>
              <div>
                <p>
                  Fabricados con componentes plásticos<br></br>
                  No contiene plásticos bio/oxo degradables<br></br>
                  Libre de sustancias peligrosas<br></br>
                </p>
              </div>
              <div>
                <p>
                  Los resultados de esta autoevaluación no comprometen al ICIPC
                  ante la persona o entidad que genera la autoevaluación ni ante
                  terceros.
                </p>
                <p>
                  Este es un servicio didáctico para orientación al público
                  general. Para un análisis detallado,{" "}
                  <a
                    href="https://icipc.org/contactanos/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    por favor contáctanos
                  </a>
                </p>
              </div>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <Link
                  className="btn btn-primary btn-lg"
                  to="/evaluation"
                  onClick={() => setDisclaimerAccept(!disclaimerAccept)}
                >
                  Acepto
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  var personalData = (
    <form onSubmit={afterSubmission}>
      <div className="container">
        <div className="row">
          <div className="col-md">
            <div className="card">
              <div className="card-header  text-center">
                Información de contacto
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="formGroupName">Nombre completo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupName"
                    placeholder="Nombres y apellidos"
                    value={name} onInput={e => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="formGroupCompany">Compañía</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupCompany"
                    placeholder="Compañía o entidad en la que trabaja"
                    value={company} onInput={e => setCompany(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="formGroupPhone">
                    Teléfono de contacto
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="formGroupPhone"
                    placeholder="Teléfono, celular de contacto"
                    value={phone} onInput={e => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="formGroupEmail">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="formGroupEmail"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={email} onInput={e => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm">
                  <button className="btn btn-primary" type="submit">Iniciar auto-evaluación</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <div>
      {!disclaimerAccept && !personalDataAccept && disclaimer}
      {disclaimerAccept && !personalDataAccept && personalData}
      {disclaimerAccept && personalDataAccept && <Survey name={name} company={company} phone={phone} email={email} ></Survey>}
    </div>
  );
};
export default Evaluation;
