import React from "react";

export default class CertificateItem extends React.Component {
  render() {
    if (this.props.label.substr(0, 4) === "http"){
      return (
        <div className="row">
          <div className="col-4">{this.props.property}:</div>
          <div className="col-8"><a href={this.props.label} target="_blank" rel="noreferrer">{"Ver Lista de Productos"}</a></div>
        </div>
      );
    }
    else
    {
      return (
        <div className="row">
          <div className="col-4">{this.props.property}:</div>
          <div className="col-8">{this.props.label}</div>
        </div>
      );
    } 
  }
}
