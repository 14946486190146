import React from "react";
import { Link } from "react-router-dom";

const SearchItem = ({
  data,
}) => {
  var checkProduct;
  if (data.product.substr(0, 4) === "http"){
    checkProduct = "Productos Varios";
  }
  else
  {
    checkProduct = data.product;
  }
  return (
    <div className="row">
      <div className="col">
        <Link to={"/verify/" + data.loopCode}>
          <p>{checkProduct}</p>
        </Link>
      </div>
      <div className="col">{data.company}</div>
      <div className="col">
        {data.certification} {data.type}
      </div>
    </div>
  );
};

export default SearchItem;
